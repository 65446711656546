@use "./node_modules/include-media/dist/_include-media.scss" as *;
.banner {
    position: fixed;
    left: 0;
    bottom: 0;
    background: rgb(0 0 0);
    padding: 20px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @include media('<tablet') {
        display: block;
    }
}

.title {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.buttons {
    white-space: nowrap;
    align-content: center;

    button {
        display: inline-block;

        &:first-child {
            margin-right: 10px;
        }
    }

    @include media('<tablet') {
        margin-top: 20px;
        text-align: center;
    }
}
