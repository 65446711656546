@use "./node_modules/include-media/dist/_include-media.scss" as *;
/* stylelint-disable property-no-vendor-prefix */
:root {
    --app-gutters: 32px;
    --header-height: 96px;
    --footer-height: 136px;

    /* general colors */
    --paper-rgb: 244 239 231; // the "paper" background color for "black on white" text
    --ink-rgb: 17 17 17; // the "ink" text color for "black on white" text
    --ink-white-rgb: 248 248 248; // the default ink ("white on black")
    --textcolor-rgb: var(--ink-white-rgb); // the scoped textcolor (for transitioning between white and black)

    /* link colors */
    --link-rgb: 159 115 33; // the link color for "black on white" text
    --link-white-rgb: 241 216 171; // the link color for "white on black" text
    --linkcolor-rgb: var(--link-white-rgb); // the scoped link color

    /* backgrounds */
    --bg-paper: url('/darkPaper2.webp');
    --bg-paper-dark: linear-gradient(rgb(0 0 0 / 25%), rgb(0 0 0 / 25%)), url('/darkPaper2.webp');

    /* transitions */
    --transition-duration: 0.2s;
    --transition-duration-relaxed: 0.6s;

    @include media('<desktop') {
        --header-height: 80px;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    height: 100%;
}

html,
body {
    color: rgb(var(--textcolor-rgb));
    background: #111;
}

body {
    background: var(--bg-paper-dark);
    background-size: 100% auto;
    overflow-y: scroll;
    font-family: var(--font-serif);

    /* sticky footer */
    min-height: 100%;
    display: flex;
    flex-direction: column;

    /* font fix */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* stylelint-disable-next-line property-no-unknown */
    font-smooth: never;
}

.content {
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
    padding: var(--header-height) 0 0;
    width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    font-weight: inherit;
}

code {
    font-size: 0.875rem;
}

input,
button,
textarea,
select {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    border: none;
}

select {
    background: transparent;
    border: 1px solid currentcolor;
}

button,
.button {
    background: none;
    border: none;
    transition: all var(--transition-duration);
    cursor: pointer;
}

button:disabled {
    pointer-events: none;
    opacity: 0.6;
}

ul,
li {
    list-style: none;
}

svg * {
    vector-effect: inherit;
}

/* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.breakfix {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;

    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;

    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.visuallyhidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

// Hide Google Captcha Badge
.grecaptcha-badge {
    visibility: hidden;
}

// react-collapse
.collapse-css-transition {
    transition: height var(--transition-duration) cubic-bezier(0.4, 0, 0.2, 1);
}
